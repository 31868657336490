import React from 'react';

import { dashboardPagesMenu, evoHuntPages } from '../../../../../menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactiveDrawerMenu from '../menu/ReactiveDrawerMenu';
import { IconButton } from '@mui/material';
import evohuntLogo from '../../../../../assets/evohunt_logo.png';

import {
	AdvisorIconWhite,
	ApplicantIconWhite,
	ArrowIcon,
	HomeIconWhite,
	IdeatoreIconWhite,
	MessageIconWhite,
	NotificationIconWhite,
} from '../../../../../assets/ui-icons/header-icon';
import { getLoggedAvatar, getLoggedUserId, getLoggedUserRole } from '../../../../../common/function/utilities';
import { ROLE } from '../../../../../constants/constants';
import { IMAGES_URL } from '../../../../../constants/environment';
import UserImage from '../../../../../assets/img/wanna/wanna1.png';

interface IEvoHuntDesktopHeaderProps {
	handleBack: () => void;
}

const EvoHuntDesktopHeader: React.FC<IEvoHuntDesktopHeaderProps> = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const mainPages: boolean =
		location.pathname === '/' ||
		location.pathname === '/preview' ||
		location.pathname === '/homeCandidato' ||
		location.pathname === '/homeAzienda' ||
		location.pathname === '/homeIdeatore' ||
		location.pathname === '/homeStartup';

	const handleHomeClick = () => {
		navigate(`../${dashboardPagesMenu.evohunt.path}`);
	};

	const handleMessagesClick = (): void => {
		navigate(`../${evoHuntPages.evohunt.subMenu.messaggi.path}`);
	};

	const handleNotificationClick = (): void => {
		navigate(`../${evoHuntPages.evohunt.subMenu.notifiche.path}`);
	};

	const handleAdvisorClick = (): void => {
		if (getLoggedUserRole() === ROLE.USER) {
			navigate(`../${evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.educational.path}`);
		} else {
			navigate(`../${evoHuntPages.evohunt.subMenu.tipsTabbed.path}`, { state: { selectedPage: "advisor" } });
		}
	};

	const imgProfileClickHandler = () => {
		if (getLoggedUserRole() === ROLE.USER) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfile.path}`);
		} else if (getLoggedUserRole() === ROLE.COMPANY) {
			navigate(`../${evoHuntPages.evohunt.subMenu.aziendaProfileHome.path}`);
		} else if (getLoggedUserRole() === ROLE.IDEATOR) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfile.path}`);
		} else if (getLoggedUserRole() === ROLE.STARTUP) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfileStartup.path}`);
		}
	};

	const handleUserRoleIcon = () => {
		switch (getLoggedUserRole()) {
			case ROLE.USER:
				return ApplicantIconWhite;
			case ROLE.IDEATOR:
				return IdeatoreIconWhite;
			default:
				return AdvisorIconWhite;
		}
	};

	return (
		<div
			className={`${location.pathname === '/' ? 'element-display-none' : 'header_container'}`}>
			<div className='curved_shadow_container'></div>
			<div className='line_shadow_container'></div>
			<div className='logo_container'>
				{mainPages ? (
					''
				) : (
					<IconButton
						edge={false}
						className='backButton'
						color='inherit'
						aria-label='Back'
						onClick={(): void => {
							window.scrollTo(0, 0);
							props.handleBack();
						}}>
						<img src={ArrowIcon} alt='EvoBackIcon' />
					</IconButton>
				)}

				<Link to={`../${dashboardPagesMenu.evohunt.path}`} className='link_logo'>
					<img src={evohuntLogo} alt='evohunt_logo' />
				</Link>
			</div>

			<div className='cta-container'>
				<div className='cta-subcontainer'>
					{getLoggedUserId() ? (
						<>
							<IconButton aria-label='home' className='cta' onClick={handleHomeClick}>
								<img src={HomeIconWhite} alt='homeIcon' />
							</IconButton>
							<IconButton
								aria-label='messages'
								className='cta'
								onClick={handleMessagesClick}>
								<img src={MessageIconWhite} alt='messagesIcon' />
							</IconButton>
							<IconButton
								aria-label='notifications'
								className='cta'
								onClick={handleNotificationClick}>
								<img src={NotificationIconWhite} alt='notificationIcon' />
							</IconButton>
							<IconButton
								aria-label='advisor'
								className='cta'
								onClick={handleAdvisorClick}>
								<img src={handleUserRoleIcon()} alt='userRole-icon' />
							</IconButton>
							<IconButton
								aria-label='profile'
								onClick={imgProfileClickHandler}
								className='profile-pic'>
								<img src={getLoggedAvatar() ? IMAGES_URL + getLoggedAvatar() : UserImage} alt='profileIcon' />
							</IconButton>
							<ReactiveDrawerMenu iconSize='2.5rem' color='white' />
						</>
					) : (
						<div style={{ width: "8rem" }}></div>
					)}
				</div>
			</div>
		</div>
	);
};

export default EvoHuntDesktopHeader;
